import React from 'react';
import Loadable from 'react-loadable';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react'; // required for calendar to work
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import listPlugin from '@fullcalendar/list';

const LoadableCalendar = Loadable({
    loader: () => import('@fullcalendar/react'),
    loading: () => <div>Loading...</div>,
    render(loaded, props) {
        let Cal = loaded.default;
        return <Cal {...props} />
    }
})

const Calendar = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [events, setEvents] = React.useState({})
    
    const handleClick = (arg) => {
        arg.jsEvent.preventDefault();
        console.log(arg.event);
        setEvents(arg.event);
        console.log(events);
        onOpen();
    }

    const calendarData = events;
    const calendarEvents = {
        title: calendarData.title,
        description: calendarData.extendedProps?.description, 
        start: calendarData._instance?.range.start.toDateString(),
        end: calendarData._instance?.range.end.toDateString()
    }
    console.log(calendarEvents);

    return (
        <>
        <LoadableCalendar
            plugins={[dayGridPlugin, googleCalendarPlugin, listPlugin]}
            initialView="listMonth"
            googleCalendarApiKey="AIzaSyBvD1lc4zuUvQ_PQvhV3nVPrjd3uprtnI0"
            events={{
                googleCalendarId: 'driggsidaho.org_hl73fnthimi30g6jac4un7c00g@group.calendar.google.com'
            }}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,listMonth'
              }}
            displayEventTime="false" // don't show the time column in list view
            eventClick={handleClick}
            // eventClick={function(arg) {

            //     // opens events in a popup window
            //     window.open(arg.event.url, '_blank', 'width=700,height=600');
          
            //     // prevents current tab from navigating
            //     arg.jsEvent.preventDefault()}
            // }
        />
            <Modal onClose={onClose} size="xl" isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{calendarEvents.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text dangerouslySetInnerHTML={{__html: calendarEvents.description}} />
                <Text>
                    {calendarEvents.start} to {calendarEvents.end}
                </Text>    
                {/* <Text>
                    {events}
                </Text> */}
              </ModalBody>
            </ModalContent>
          </Modal>
      </>
    )
 }



 export default Calendar;